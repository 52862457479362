// Core
import PropTypes from "prop-types";
import React from "react";
// Components
import {
  Lazyiframe,
  RichText,
} from "../../components/";

class Contact extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-contact-${data.id}`;

    return (
      <section className="container py50 rel">
        <div className="anchor" id={section_id}></div>

        <div className="maxw600 mxa tac">
          <h2
            className="h1 m0"
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-once="true"
          >
            {data.title}
          </h2>

          {data.paragraphText && (
            <div
              data-aos="fade-up"
              data-aos-anchor={`#${section_id}`}
              data-aos-delay="200"
              data-aos-once="true"
            >
              <RichText json={JSON.parse(data.paragraphText.raw)} />
            </div>
          )}
        </div>

        <div className="maxw800 mt50 mxa">
          <div
            className="aic card flex jcb my20 px30 py10 rpx10"
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-delay="400"
            data-aos-once="true"
          >
            <a
              className="bold large link rp"
              href={`mailto:${data.email}`}
            >
              {data.email}
            </a>

            <a
              className="icon-button"
              href={`mailto:${data.email}`}
            >
              <svg
                aria-labelledby={`${section_id}-icon-mail-title`}
                fill="none"
                height="24"
                role="img"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="24"
              >
                <title id={`${section_id}-icon-mail-title`}>Send Email</title>
                <use href="/images/feather-icons.svg#mail" xlinkHref="/images/feather-icons.svg#mail" />
              </svg>
            </a>
          </div>

          <div
            className="aic card flex jcb my20 px30 py10 rpx10"
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-delay="600"
            data-aos-once="true"
          >
            <a
              className="bold large link rp"
              href={`tel:+61${data.phoneNumber.substring(1, 10)}`}
            >
              {data.phoneNumber.substring(0, 4)} {data.phoneNumber.substring(4, 7)} {data.phoneNumber.substring(7, 10)}
            </a>

            <a
              className="icon-button"
              href={`tel:+61${data.phoneNumber.substring(1, 10)}`}
            >
              <svg
                aria-labelledby={`${section_id}-icon-phone-title`}
                fill="none"
                height="24"
                role="img"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="24"
              >
                <title id={`${section_id}-icon-phone-title`}>Call</title>
                <use href="/images/feather-icons.svg#phone" xlinkHref="/images/feather-icons.svg#phone" />
              </svg>
            </a>
          </div>

          <div
            className="card"
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-delay="800"
            data-aos-once="true"
          >
            <div className="aic flex jcb px30 py10 rpx10">
              <a
                className="bold large link rp"
                href={data.googleDirectionsLink}
                rel="noreferrer noopener"
                target="_blank"
              >
                {data.location}
              </a>

              <a
                className="icon-button"
                href={data.googleDirectionsLink}
                rel="noreferrer noopener"
                target="_blank"
              >
                <svg
                  aria-labelledby={`${section_id}-icon-compass-title`}
                  fill="none"
                  height="24"
                  role="img"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  width="24"
                >
                  <title id={`${section_id}-icon-compass-title`}>Get Directions</title>
                  <use href="/images/feather-icons.svg#compass" xlinkHref="/images/feather-icons.svg#compass" />
                </svg>
              </a>
            </div>

            <Lazyiframe
              height="400px"
              id="contact-map"
              src={data.embedMapLink}
              title="Omega Holistic Location"
              width="100%"
            />
          </div>
        </div>
      </section>
    );
  }
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;
