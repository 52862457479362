// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  RichText,
} from "../../components/";

class Text extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-text-${data.id}`;

    return (
      <section className="py50 rel">
        <div className="anchor" id={section_id}></div>

        <div className={`container container--small ${styles.root}`}>
          <h2
            className="fdc flex h1 mt0 tac"
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-once="true"
          >
            {data.title}
          </h2>

          <div
            className={[
              styles[`text--${data.textAlignment}`],
              data.card ? "card p50 rp20" : "",
            ].join(" ")}
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-delay="200"
            data-aos-once="true"
          >
            <RichText json={JSON.parse(data.paragraphText.raw)} />
          </div>
        </div>

        {data.backgroundImage && (
          <div
            className={[
              styles.background,
              data.backgroundImageOverlay ? "opacity-05" : "",
              "rellax rellax--background",
            ].join(" ")}
          >
            <GatsbyImage
              alt={data.backgroundImage.title}
              image={data.backgroundImage.gatsbyImageData}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        )}
      </section>
    );
  }
}

Text.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Text;
