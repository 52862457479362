// Core
import { graphql } from "gatsby";
import React from "react";
import get from "lodash/get";
// Components
import Layout from "../../layout/";
// Sections
import {
  Contact,
  Hero,
  IconGrid,
  Testimonials,
  Text,
  TextWithImage,
  TreatmentsCards,
  TwoColumnText,
} from "../../sections/";
const sections = {
  Contact: Contact,
  Hero: Hero,
  IconGrid: IconGrid,
  Testimonials: Testimonials,
  Text: Text,
  TextWithImage: TextWithImage,
  TreatmentsCards: TreatmentsCards,
  TwoColumnText: TwoColumnText,
};

class StandardPageTemplate extends React.Component {
  render() {
    const page_data = get(this.props, "data.contentfulPageStandard");

    return (
      <Layout
        seo={{
          description: page_data.description ? page_data.description.description : undefined,
          image: page_data.image.fixed.src,
          pathname: page_data.pathname,
          title: page_data.title,
        }}
      >
        {page_data.sections.map((section, index) => {
          const Section = sections[section.internal.type.replace("ContentfulSection", "")];

          return (
            <Section
              data={section}
              key={index}
            />
          );
        })}
      </Layout>
    );
  }
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query StandardPageTemplateQuery($pathname: String!) {
    contentfulPageStandard(pathname: { eq: $pathname }) {
      pathname
      title
      description {
        description
      }
      image {
        fixed(height: 628, width: 1200) {
          src
        }
      }
      sections {
        ... on Node {
          ... on ContentfulSectionContact {
            anchorId
            internal {
              type
            }
            id
            title
            paragraphText {
              raw
            }
            email
            phoneNumber
            location
            googleDirectionsLink
            embedMapLink
          }
          ... on ContentfulSectionHero {
            anchorId
            internal {
              type
            }
            id
            title
            subtitle
            paragraphText {
              raw
            }
            buttonLabel
            buttonUrl
            backgroundImage {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            backgroundImageOverlay
          }
          ... on ContentfulSectionIconGrid {
            anchorId
            internal {
              type
            }
            id
            iconTiles {
              label
              svgName
              circleOutline
            }
          }
          ... on ContentfulSectionTestimonials {
            anchorId
            internal {
              type
            }
            id
            title
            testimonials {
              author
              quote {
                raw
              }
            }
          }
          ... on ContentfulSectionText {
            anchorId
            internal {
              type
            }
            id
            title
            card
            paragraphText {
              raw
            }
            textAlignment
            backgroundImage {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            backgroundImageOverlay
          }
          ... on ContentfulSectionTextWithImage {
            anchorId
            internal {
              type
            }
            id
            title
            paragraphText {
              raw
            }
            textAlignment
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            imageAlignment
          }
          ... on ContentfulSectionTreatmentsCards {
            anchorId
            internal {
              type
            }
            id
            title
            paragraphText {
              raw
            }
            treatmentCards {
              name
              descriptionParagraphText {
                raw
              }
              prices {
                minutes
                price
              }
              addOns
              bookingUrl
            }
          }
          ... on ContentfulSectionTwoColumnText {
            anchorId
            internal {
              type
            }
            id
            title
            cards
            leftParagraphText {
              raw
            }
            leftTextAlignment
            rightParagraphText {
              raw
            }
            rightTextAlignment
            backgroundImage {
              title
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            backgroundImageOverlay
          }
        }
      }
    }
  }
`;
