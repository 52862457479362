// Core
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  RichText,
} from "../../components/";
// Utilities
import {
  richTextWordCount,
} from "../../utilities/";

class Testimonials extends React.Component {
  componentDidMount() {
    const grid = document.getElementById("section-testimonials-grid");
    const scrollbar = document.getElementById("section-testimonials-scrollbar");
    const scrollbar_thumb = scrollbar.children[0];

    // Update custom scrollbar position on horizontal scroll
    grid.addEventListener("scroll", event => {
      // Only perform calculations when scrollbar is visible
      if (window.innerWidth < 600) {
        // Visible width of the grid
        const offset_width = event.currentTarget.offsetWidth;
        // Pixels scrolled
        const scroll_left = event.currentTarget.scrollLeft;
        // Full width of the grid
        const scroll_width = event.currentTarget.scrollWidth;

        // Calculations
        const scroll_percent = scroll_left / (scroll_width - offset_width);
        const scrollbar_width = scrollbar.offsetWidth;
        const scrollbar_thumb_width = offset_width / scroll_width * scrollbar_width;

        // Update the scrollbar thumb style
        scrollbar_thumb.style.left = `${(scrollbar_width - scrollbar_thumb_width) * scroll_percent}px`;
        scrollbar_thumb.style.width = `${scrollbar_thumb_width}px`;
      }
    });
  }

  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-testimonials-${data.id}`;

    return (
      <section className="container py50 rel">
        <div className="anchor" id={section_id}></div>

        <div className="rel">
          <h2 className="h1 tac">
            {data.title}
          </h2>

          <div className="rel">
            <div
              className={styles.grid}
              id="section-testimonials-grid"
            >
              {data.testimonials.map((testimonial, index) => {
                const character_count = richTextWordCount({
                  count: "characters",
                  raw: JSON.parse(testimonial.quote.raw),
                });

                return (
                  <blockquote
                    className={[
                      styles.testimonial,
                      character_count > 200 ? styles["testimonial--long"] : "",
                    ].join(" ")}
                    key={index}
                  >
                    <RichText json={JSON.parse(testimonial.quote.raw)} />

                    <cite className="bold italic p">
                      &mdash; {testimonial.author}
                    </cite>
                  </blockquote>
                );
              })}
            </div>

            <div
              aria-hidden="true"
              className={styles.scrollbar}
              id="section-testimonials-scrollbar"
            >
              <div className={styles.scrollbar__thumb}></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonials.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Testimonials;
