// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  RichText,
} from "../../components/";

class Hero extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-hero-${data.id}`;

    return (
      <section
        className={[
          styles.hero,
          styles["hero--center"],
          "rel",
        ].join(" ")}
      >
        <div className="anchor" id={section_id}></div>

        <div className={`${styles.inner} container`}>
          <h1 className="fdc flex mt0 rh2 tac">
            <span className={data.title.replace(/ /g, "").length < 10 ? styles["hero__title--large"] : ""}>
              {data.title}
            </span>

            {data.subtitle && (
              <span className={`${data.subtitle.replace(/ /g, "").length <= 15 ? "h2 rh3" : "h3"} inline-block`}>
                {data.subtitle}
              </span>
            )}
          </h1>

          {data.paragraphText && (
            <div className={`${styles.paragraph} maxw600 mxa`}>
              <RichText json={JSON.parse(data.paragraphText.raw)} />
            </div>
          )}

          <div className="mt30">
            <a
              className="button button--large"
              href={data.buttonUrl}
              rel="noreferrer noopener"
              target="_blank"
            >
              {data.buttonLabel}
            </a>
          </div>
        </div>

        {data.backgroundImage && (
          <div
            className={[
              styles.background,
              data.backgroundImageOverlay ? "opacity-05" : "",
              "rellax rellax--background",
            ].join(" ")}
          >
            <GatsbyImage
              alt={data.backgroundImage.title}
              image={data.backgroundImage.gatsbyImageData}
              loading="eager"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        )}
      </section>
    );
  }
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
