// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  RichText,
} from "../../components/";

class TextWithImage extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-text-with-image-${data.id}`;

    return (
      <section className="py100 rel rpy50">
        <div className="anchor" id={section_id}></div>

        <div className={`container ${styles.root}`}>
          <div
            className={[
              data.imageAlignment === "right" ? styles.left : styles.right,
              styles[`text--${data.textAlignment}`],
            ].join(" ")}
          >
            <h2
              className="fdc flex h1 mt0"
              data-aos="fade-up"
              data-aos-anchor={`#${section_id}`}
              data-aos-once="true"
            >
              {data.title}
            </h2>

            <div
              data-aos="fade-up"
              data-aos-anchor={`#${section_id}`}
              data-aos-delay="200"
              data-aos-once="true"
            >
              <RichText json={JSON.parse(data.paragraphText.raw)} />
            </div>
          </div>

          <div
            className={styles[data.imageAlignment]}
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-once="true"
          >
            <GatsbyImage
              alt={data.image.title}
              image={data.image.gatsbyImageData}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

TextWithImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TextWithImage;
