// Core
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  RichText,
} from "../../components/";

class TreatmentsCards extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-treatments-cards-${data.id}`;

    return (
      <section className="container py50 rel">
        <div className="anchor" id={section_id}></div>

        <div className="maxw600 mb50 mxa tac">
          <h2
            className="fdc flex h1 mt0"
            data-aos="fade-up"
            data-aos-anchor={`#${section_id}`}
            data-aos-once="true"
          >
            {data.title}
          </h2>

          {data.paragraphText && (
            <div
              data-aos="fade-up"
              data-aos-anchor={`#${section_id}`}
              data-aos-delay="200"
              data-aos-once="true"
            >
              <RichText json={JSON.parse(data.paragraphText.raw)} />
            </div>
          )}
        </div>

        <div className={styles.grid}>
          {data.treatmentCards.map((treatment, index) => {
            return (
              <div
                className="card flex fdc p20"
                data-aos="fade-up"
                data-aos-anchor={`#${section_id}`}
                data-aos-delay={400 + 200 * index}
                data-aos-once="true"
                key={index}
              >
                <div>
                  <h3 className="mt0">{treatment.name}</h3>

                  <RichText json={JSON.parse(treatment.descriptionParagraphText.raw)} />
                </div>

                <div className={styles.prices}>
                  {treatment.prices.map((item, index) => {
                    return (
                      <div
                        className={styles.price}
                        key={index}
                      >
                        <div
                          aria-hidden="true"
                          className={styles.clock}
                          focusable="false"
                          role="none"
                        >
                          {item.minutes >= 15 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--15"]}`}></div>}
                          {item.minutes >= 30 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--30"]}`}></div>}
                          {item.minutes >= 45 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--45"]}`}></div>}
                          {item.minutes >= 60 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--60"]}`}></div>}
                          {item.minutes >= 75 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--75"]}`}></div>}
                          {item.minutes >= 90 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--90"]}`}></div>}
                          {item.minutes >= 105 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--105"]}`}></div>}
                          {item.minutes >= 120 && <div className={`${styles.clock__minutes} ${styles["clock__minutes--120"]}`}></div>}
                        </div>

                        <p className={styles.price__time}>
                          <span>{item.minutes}</span>
                          <span> minutes</span>
                        </p>
                        <p className={styles.price__value}>
                          <span>$</span>
                          <span>{item.price}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>

                {treatment.addOns?.length > 0 && (
                  <div className="aie flex jcb mta">
                    <div className="color-secondary">
                      <p className="m0 small">Available Add-Ons:</p>

                      {treatment.addOns.map((add_on, index) => {
                        return (
                          <p className="m0 small" key={index}>{add_on}</p>
                        );
                      })}
                    </div>

                    {treatment.bookingUrl && (
                      <a
                        className="button"
                        href={treatment.bookingUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Book
                      </a>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

TreatmentsCards.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TreatmentsCards;
