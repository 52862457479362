// Core
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

class IconGrid extends React.Component {
  render() {
    const data = this.props.data;
    const section_id = data.anchorId || `section-icon-grid-${data.id}`;

    return (
      <section className="py50 rel">
        <div className="anchor" id={section_id}></div>

        <div
          className={[
            styles.grid,
            styles[`grid--${data.iconTiles.length}`],
            "container",
          ].join(" ")}
        >
          {data.iconTiles.map((tile, index) => {
            return (
              <div
                className={styles.tile}
                data-aos="fade-up"
                data-aos-anchor={section_id}
                data-aos-delay={200 * index}
                data-aos-once="true"
                key={index}
              >
                <div
                  className={[
                    styles.tile__icon,
                    tile.circleOutline ? styles["tile__icon--outline"] : "",
                  ].join(" ")}
                >
                  <svg
                    aria-hidden="true"
                    fill="currentColor"
                    focusable="false"
                    height="24"
                    role="img"
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    width="24"
                  >
                    <use href={`/images/icons.svg#${tile.svgName}`} xlinkHref={`/images/icons.svg#${tile.svgName}`} />
                  </svg>
                </div>

                <p>{tile.label}</p>
              </div>
            );
          })}
        </div>
      </section>
    );
  }
}

IconGrid.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IconGrid;
